<template>
	<div class="time-wrapper">
		<vue-timepicker
			:format="format"
			:value="timeObject"
			hide-clear-button
			:ref="'time-' + parent"
			@change="changeTime"
		/>
		<font-awesome-icon icon="caret-down" />
	</div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
export default {
	props : {
		parent : String,
		time   : String,
		name   : String,
		format : {
			type : String,
			default () {
				return 'hh:mm A'
			}
		}
	},
	computed : {
		timeObject () {
			const moment = this.$moment(this.time || '10:00 AM', 'hh:mm A')
			return {
				hh : moment.format('hh'),
				mm : moment.format('mm'),
				A  : moment.format('A')
			}
		}
	},
	components : {
		VueTimepicker
	},
	methods : {
		changeTime (event) {
			const { data } = event
			this.$emit('change', {
				key   : this.name,
				value : `${data.hh}:${data.mm} ${data.A}`
			})
		}
	},
	mounted () {
		setTimeout(() => {
			this.$refs['time-' + this.parent].$el.querySelector('.dropdown').classList.add('set-height')

			this.$refs['time-' + this.parent].$el.querySelectorAll('.dropdown .hint').forEach(el => {
				el.innerHTML = ''
			})

			setTimeout(() => {
				this.$refs['time-' + this.parent].$el.querySelectorAll('.dropdown ul li.active').forEach(el => {
					if (127 < el.offsetTop) {
						el.parentNode.scrollTop = el.offsetTop
					}
				})

				this.$refs['time-' + this.parent].$el.querySelector('.dropdown').classList.remove('set-height')
			}, 0)
		}, 0)
	}
}
</script>

<style lang="scss">
.time-wrapper {
	position: relative;
	color: #495057;

	.labels {
		display: none;
	}

	.time-picker {
		width: 100%;

		input.display-time {
			width: 100%;
			background-color: #fff;
			font-size: 1.1em;
			font-family: crimson;
			margin-bottom: 0.6em;
			padding: 0.8em 0.8em 0.4em;
			border-radius: 0;
			border: none;
			color: #495057;

			&:focus {
				outline: 0;
			}
		}

		.dropdown {
			width: 100%;
			border: 1px solid #ccc;
			border-radius: 0;
			box-shadow: none;
			font-family: crimson;

			&.set-height {
				display:block !important;
				visibility: hidden;
			}

			.select-list {
				width: 100%;

				ul {
					position: relative;

					li.active {
						background: #4163ae;
					}

					&.hours {
						.hint:before {
							content: 'Hour'
						}
					}
					&.minutes {
						.hint:before {
							content: 'Minute'
						}
					}
					&.apms {
						.hint:before {
							content: 'AM/PM'
						}
					}
				}
			}
		}
	}

	.fa-caret-down {
		position: absolute;
		right: 15px;
		top: 13px;
		cursor: pointer;
	}
}
</style>