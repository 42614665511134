<template>
	<nav :class="{ 'pull-right': pullRight }">
		<input-button
			:to="{ name: link, params: { key: 'save' === link ? registrationKey : null } }"
			v-for="link in links"
			:key="link"
			active-class="active"
			:type="linkData[link].type"
		>
			{{ linkData[link].label }}
		</input-button>
	</nav>
</template>

<script>
export default {
	props : {
		links : {
			type    : Array,
			default : () => {
				return [
					'account',
					'attendees',
					'events',
					'payment',
					'register',
					'logout'
				]
			}
		},
		pullRight : {
			type    : Boolean,
			default : true
		}
	},
	data () {
		return {
			linkData : {
				account : {
					type  : 'secondary',
					label : 'My Account'
				},
				attendees : {
					type  : 'secondary',
					label : 'Attendees'
				},
				events : {
					type  : 'secondary',
					label : 'Events'
				},
				payment : {
					type  : 'secondary',
					label : 'Make A Payment'
				},
				register : {
					type  : 'primary',
					label : 'Register'
				},
				logout : {
					type  : 'secondary',
					label : 'Log Out'
				},
				save : {
					type  : 'primary',
					label : 'Save and Log Out'
				}
			}
		}
	},
	computed : {
		registrationKey () {
			// Sometimes the route ID is not set, so we return a string to prevent a console warning.
			return this.$route.params.key || 'save'
		}
	}
}
</script>