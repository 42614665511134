<template>
	<div class="select-wrapper">
		<select
			autocomplete="off"
			class="form-control"
			@change="changeSelect"
			@input="$emit('input', $event.target.value)"
			:name="name"
			:disabled="disabled"
			:value="selected"
		>
			<option
				v-if="placeholder"
				selected
				value=""
				disabled
			>
				{{ placeholder }}
			</option>
			<option
				v-for="(option, index) in options"
				:value="option.value"
				:key="index"
				:class="option.classes"
				:selected="isSelected(option.value)"
			>{{ option.label }}</option>
		</select>
		<font-awesome-icon icon="caret-down" />
	</div>
</template>

<script>
export default {
	props : {
		name        : String,
		options     : Array,
		selected    : String,
		selector    : String,
		disabled    : Boolean,
		placeholder : String
	},
	methods : {
		isSelected (value) {
			return value === this.selected
		},
		changeSelect (event) {
			this.$emit('change', {
				[event.target.getAttribute('name')] : event.target.value
			})
		}
	}
}
</script>

<style lang="scss">
.select-wrapper {
	position: relative;
	color: #495057;
	background-color: #fff;

	.fa-caret-down {
		position: absolute;
		right: 15px;
		top: 13px;
		cursor: pointer;
	}
}
select {
	-webkit-appearance: none;
	background-color: transparent !important;
	position: relative;
	z-index: 1;
	cursor: pointer;

	&::-ms-expand {
		display: none;
	}

	&:focus {
		background-color: transparent !important;
	}
}
</style>