<template>
	<section class="error" v-if="notices.length">
		<div class="container">
			<h3>{{ title }}</h3>
			<div>
				<div v-for="(notice, index) in notices" :key="index"><em>{{ notice }}</em></div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props : {
		title : {
			type     : String,
			required : true
		},
		notices : {
			type     : Array,
			required : true
		}
	}
}
</script>