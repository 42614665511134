<template>
	<footer>
		<div class="container">
			<div class="copyright">
				&copy; {{ year }} {{ platformName }}. All Rights Reserved.
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	data () {
		return {
			platformName : process.env.VUE_APP_PLATFORM_NAME
		}
	},
	computed : {
		year () {
			const d = new Date()
			return d.getFullYear()
		}
	}
}
</script>

<style lang="scss">
.white {
	.copyright {
		a {
			color: #2d2d2d;
		}
	}
}
</style>