<template>
	<div class="birthdate-wrapper">
		<datepicker
			:placeholder="placeholder"
			input-class="form-control datepicker"
			:value="birthdate"
			format="MMMM d, yyyy"
			initial-view="year"
			:disabledDates="disabledDates"
			@input="dateChange"
			:ref="'birthdate-' + parent"
		/>
		<font-awesome-icon icon="times" v-if="birthdate" @click="clearDate" />
		<font-awesome-icon icon="caret-down" @click="showCalendar" />
	</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
export default {
	data () {
		return {
			disabledDates : {
				from : new Date()
			}
		}
	},
	props : {
		birthdate   : String,
		parent      : String,
		placeholder : String
	},
	components : {
		Datepicker
	},
	methods : {
		dateChange (date) {
			if (date) {
				const monthNames = [
					'January', 'February', 'March',
					'April', 'May', 'June', 'July',
					'August', 'September', 'October',
					'November', 'December'
				]

				date = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
			}
			this.$emit('change', {
				birthdate : date
			})
		},
		clearDate () {
			this.$refs[`birthdate-${this.parent}`].clearDate()
		},
		showCalendar () {
			this.$refs[`birthdate-${this.parent}`].showCalendar()
		}
	},
	mounted () {
		document.querySelector('.vdp-datepicker').addEventListener('click', event => {
			event.stopImmediatePropagation()
			event.preventDefault()
			if (event.target.querySelector('input')) {
				event.target.querySelector('input').click()
			}
		})
	}
}
</script>

<style lang="scss">
.birthdate-wrapper {
	position: relative;
	color: #495057;

	.vdp-datepicker {
		position: relative;

		&__calendar {
			width: 370px;
			top: 42px;

			.cell.selected {
				color: #fff;
				background: #4163ae;
			}

			.cell:not(.blank):not(.disabled).day:hover {
				border: 1px solid #4163ae;
			}
		}

		.datepicker {
			background-color: #fff;
		}
	}

	.fa-times {
		position: absolute;
		right: 35px;
		top: 13px;
		cursor: pointer;
	}

	.fa-caret-down {
		position: absolute;
		right: 15px;
		top: 13px;
		cursor: pointer;
	}
}
</style>