<template>
	<core-notice
		class="saved-registration"
		v-if="savedRegistration.id && !current.restricted"
		:custom="true"
	>
		<div class="row">
			<div class="col-md-8">
				<h5>You have a saved registration</h5>
			</div>
			<div class="col-md-4 col-md-text-right">
				<input-button
					:to="savedRegistration.url"
					type="dark"
				>Resume</input-button>
				<input-button
					:to="{ name: 'register-cancel', params: { key: savedRegistration.id } }"
					type="secondary"
				>Cancel</input-button>
			</div>
		</div>
	</core-notice>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	computed : {
		...mapState('account', [ 'current' ]),
		...mapGetters('account', [ 'savedRegistration' ])
	}
}
</script>

<style lang="scss">
.saved-registration {
	h5 {
		line-height: 39px;
		margin: 0;
	}

	a,
	button {
		margin-bottom: 0;
	}
}
</style>