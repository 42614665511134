<template>
	<section :class="classes" v-if="notices.length || custom">
		<div class="container">
			<h3>{{ title }}</h3>
			<div
				v-if="!custom"
			>
				<div v-for="(notice, index) in notices" :key="index"><em>{{ notice }}</em></div>
			</div>
			<slot
				v-if="custom"
			/>
		</div>
	</section>
</template>

<script>
export default {
	props : {
		title   : String,
		notices : {
			type : Array,
			default () {
				return []
			}
		},
		custom : {
			type : Boolean,
			default () {
				return false
			}
		},
		classes : {
			type     : String,
			required : false,
			default () {
				return 'error'
			}
		}
	}
}
</script>