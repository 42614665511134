<template>
	<div>
		<router-view :key="$route.fullPath" />
		<core-admin-switcher
			v-if="$store.state.isAdmin"
		/>
	</div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
	methods : {
		...mapMutations([ 'setAdmin' ])
	},
	created () {
		if (this.$storage.cookies.get('_re2_admin')) {
			this.setAdmin(true)
		}
	}
}
</script>

<style lang="scss">
@import '@/registration/assets/styles/main.scss';
</style>