<template>
	<nav class="arrows">
		<button
			v-for="(s, index) in stages"
			:key="index"
			class="btn"
			:class="{
				'btn-inactive': (index + 1) > stage || ((index + 1) < stage && 2 === section),
				'btn-previous': (index + 1) < stage && (3 !== stage || 2 !== section),
				'btn-active': (index + 1) === stage
			}"
			@click="maybeNavigate(s, index)"
		>{{ s.label }}</button>
	</nav>
</template>

<script>
export default {
	props : {
		stage : {
			type     : Number,
			required : true
		},
		section : {
			type : Number,
			default () {
				return 1
			}
		},
		hide : {
			type : Array,
			default () {
				return []
			}
		}
	},
	data () {
		return {
			allStages : {
				1 : [
					{ label: 'Attendee', slug: 'attendee' },
					{ label: 'Events', slug: 'events' },
					{ label: 'Registration Questions', slug: 'questions' },
					{ label: 'Financial Options', slug: 'financial-options' },
					{ label: 'Travel Options', slug: 'travel-options' }
				],
				2 : [
					{ label: 'Summary', slug: 'summary' },
					{ label: 'Payment', slug: 'payment' },
					{ label: 'Confirmation', slug: 'confirmation' }
				]
			}
		}
	},
	computed : {
		stages () {
			return this.allStages[this.section].filter(stage => -1 === this.hide.indexOf(stage.slug))
		}
	},
	methods : {
		maybeNavigate (s, index) {
			if (index + 1 === this.stage || index + 1 > this.stage) {
				return
			}

			if (2 === this.section && 3 === this.stage) {
				return
			}

			this.$router.push({ name: `register-${s.slug}` })
		}
	}
}
</script>

<style lang="scss">
nav.arrows {
	margin-bottom: 25px;

	.btn {
		margin-right: 4px;
		position: relative;
		font-family: nova;
		letter-spacing: .2em;
		text-transform: uppercase;
		font-size: 10px;
		padding: 10px 1em 7px 3em;
		margin-bottom: .4em;

		&:before {
			content: '';
			display: block;
			border: 16px solid transparent;
			border-right-width: 0;
			position: absolute;
			top: -1px;
			right: -21px;
			border-left-color: #4163ae;
			z-index: 1;
		}

		&:after {
			content: '';
			display: block;
			border: 16px solid transparent;
			border-right-width: 0;
			position: absolute;
			top: -1px;
			right: -17px;
			border-left-color: #4163ae;
			z-index: 2;
			transition: all 0.15s ease-in-out;
		}

		&:focus {
			box-shadow:none;
		}

		&.btn-inactive {
			&:after {
				border-left-color: rgba( 0,0,0,0.4 );
			}
		}

		&.btn-previous {
			color: #fff;
			cursor: pointer;

			&:after {
				border-left-color: #232e46;
			}

			&:hover:after {
				border-left-color: #4163ae;
			}
		}
	}
}
.red {
	nav.arrows {
		.btn:before {
			border-left-color: #ad4242;
		}
	}
}
.white {
	nav.arrows {
		.btn {
			&:before {
				border-left-color: #fff;
			}
			&:after {
				border-left-color: #2d2d2d;
			}

			&.btn-inactive {
				&:after {
					border-left-color: #ccc;
				}
			}

			&.btn-previous {
				&:after {
					border-left-color: #ccc;
				}
				&:hover:after {
					border-left-color: #2d2d2d;
				}
			}
		}
	}
}
</style>