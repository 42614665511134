import Vue from 'vue'

import './fontawesome'

import {
	constants
} from '@/utils'

import superagent from 'superagent'
import { v4 as uuidv4 } from 'uuid'

import registrationStore from '@/registration/store'

// Sentry
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

// Log Sentry errors.
if ('dev' !== process.env.VUE_APP_ENV) {
	Sentry.init({
		dsn          : process.env.VUE_APP_REGISTRATION_SENTRY_URL,
		integrations : [ new Integrations.Vue({ Vue, attachProps: true, logErrors: true }) ],
		release      : process.env.VUE_APP_VERSION,
		environment  : process.env.VUE_APP_ENV
	})
	Sentry.configureScope(scope => {
		scope.addEventProcessor(async (event) => {
			// Magic here to save log to the server.
			window.errorKey = uuidv4()
			let url   = null
			await superagent.agent()
				.post(`${constants.BASE_API_URL}/error-log/${window.errorKey}/`)
				.send({
					registration : {
						...registrationStore.state
					}
				})
				.then(response => (url = response.body))

			return {
				...event,
				extra : {
					...event.extra,
					vuex_state : url
				}
			}
		})
	})
}

Vue.prototype.$admin = false