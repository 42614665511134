<template>
	<div v-if="getAttendee(current.attendee)">
		<small>
			Attendee: {{ getAttendee(current.attendee).first_name + ' ' + getAttendee(current.attendee).last_name }}<br />
			Event: {{ currentEvent.name }}
		</small>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
	props : {
		current : {
			type     : Object,
			required : true
		}
	},
	computed : {
		...mapState('register', [ 'attendees' ]),
		...mapGetters('register', [ 'currentEvent' ]),
		...mapGetters('attendees', {
			getAttendee : 'get'
		})
	}
}
</script>