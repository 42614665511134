import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
	faCreditCard
} from '@fortawesome/free-regular-svg-icons'
import {
	faCaretDown,
	faCaretRight,
	faCheckSquare,
	faMoneyCheckAlt,
	faPencilAlt,
	faTimes,
	faTimesCircle
} from '@fortawesome/free-solid-svg-icons'
import {
	faCcAmex,
	faCcDiscover,
	faCcMastercard,
	faCcVisa
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
	faCaretDown,
	faCaretRight,
	faCheckSquare,
	faCreditCard,
	faCcAmex,
	faCcDiscover,
	faCcMastercard,
	faCcVisa,
	faMoneyCheckAlt,
	faPencilAlt,
	faTimes,
	faTimesCircle
)

Vue.component('font-awesome-icon', FontAwesomeIcon)