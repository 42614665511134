<template>
	<aside :class="registration.status">
		<div class="row">
			<div class="col-lg-5 col-sm-9 my-auto meta">
				<strong>
					<router-link
						:to="{ name : 'register-confirmation', params : { key: registration.key } }"
					>
						{{ attendeeName }}
					</router-link>
				</strong>
				{{ $moment.utc(registration.created).local().format('MM/DD/YYYY') }}<br>
				{{ registration.event.name }}<br>
				{{ registration.event.attendee_type_name }}<br>
				{{ registration.id }}
			</div>
			<div class="col-lg-2 col-sm-3 text-center totals my-auto">
				<label>Total</label>
				Total: {{ $amounts.total([registration]) | currency }}<br />
				Paid: {{ $amounts.paid([registration]) | currency }}<br />
				<span
					v-if="'waitlist' !== registration.status && 'cancelled' !== registration.status"
				>
					Balance:&nbsp;{{ $amounts.due([registration]) | currency }}
				</span>
			</div>
			<div class="col-lg-5 col-md-12 text-center my-auto links">
				<input-button
					:to="{ name : 'register-confirmation', params : { key : registration.key } }"
					type="primary"
				>View</input-button>
				<input-button
					:to="{ name : 'events-registration-edit', params : { key : registration.key, id : registration.id } }"
					v-if="'cancelled' !== registration.status"
					type="secondary"
				>Edit</input-button>
				<input-button
					:to="{ name : 'events-cancel' }"
					v-if="'cancelled' !== registration.status"
					type="secondary"
				>Cancel</input-button>
			</div>
		</div>
	</aside>
</template>

<script>
export default {
	props : {
		registration : {
			type     : Object,
			required : true
		}
	},
	computed : {
		attendeeName () {
			return (this.registration.attendee.first_name + ' ' + this.registration.attendee.last_name).trim()
		},
		getTotal () {
			let subtotal = 0
			this.registration.financial_options.forEach(option => {
				subtotal += option.quantity * option.amount
			})
			this.registration.discounts.forEach(discount => {
				subtotal -= discount.amount
			})
			return subtotal
		},
		getAmountPaid () {
			let paid = 0
			this.registration.transactions.forEach(transaction => {
				paid += parseFloat(transaction.registration_amount)
			})
			return paid
		},
		getAmountDue (registration) {
			return this.getSubtotal(registration) - this.getAmountPaid(registration)
		}
	}
}
</script>