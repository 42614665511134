<template>
	<input-button
		type="primary"
		class="admin-switcher"
		@click="returnToAdmin"
	>
		Return to Admin
	</input-button>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
	methods : {
		...mapMutations([ 'setAdmin' ]),
		returnToAdmin () {
			// Turn on the loader.
			this.$store.commit('loading', true)

			// Turn off the admin setting in vuex.
			this.setAdmin(false)

			// Get the original admin URL from the cookie.
			const adminUrl = this.$storage.cookies.get('_re2_admin')

			// Delete the admin URL cookie.
			this.$storage.cookies.remove('_re2_admin')

			// Set a new cookie letting the admin know we are switching back.
			this.$storage.cookies.set('_re2_admin_switch', true)

			// Redirect to the admin using the url or /admin as a fallback.
			window.location = this.$url.redirect(adminUrl || '/admin')
		}
	}
}
</script>

<style lang="scss">
.admin-switcher {
	position: fixed;
	bottom: 20px;
	left: 20px;
	margin: 0;
}
</style>