<template>
	<component
		:is="getComponent"
		:type="getButtonType"
		:class="allClasses"
		:to="to"
		@click="$emit('click')"
		:active-class="activeClass"
	>
		<slot />
		&nbsp;
		<font-awesome-icon icon="caret-right" />
	</component>
</template>

<script>
export default {
	props : {
		submit : {
			type    : Boolean,
			default : false
		},
		type : {
			type     : String,
			required : true
		},
		classes : {
			type : String,
			default () {
				return ''
			}
		},
		to : {
			required : false
		},
		activeClass : String
	},
	computed : {
		getComponent () {
			if (this.to) {
				return 'router-link'
			}

			return 'button'
		},
		allClasses () {
			return (`btn btn-${this.type} ${this.classes}`).trim()
		},
		getButtonType () {
			return this.to ? null : (this.submit ? 'submit' : 'button')
		}
	}
}
</script>

<style lang="scss">
.btn {
	line-height: 1.3em;
	text-transform: capitalize;
	border-radius: 0;
	font-family: crimson;
	letter-spacing: 0;
	margin-right: 1em;
	margin-bottom: 1em;

	&.single {
		margin-right: 0;
	}

	&.btn-secondary {
		background: transparent;
		box-shadow: inset 0 0 0 0 #fff;
		border: 1px solid #fff;
		color: #fff;
		cursor: pointer;
		transition: all 0.1s ease-in-out;

		&:hover,
		&.active {
			box-shadow: inset 0 0 0 3em #fff;
			color: #4163ae;
		}
	}

	&.btn-primary {
		background: #ad4242;
		box-shadow: inset 0 0 0 0 #ad4242;
		border: 1px solid #ad4242;
		color: #fff; cursor: pointer;
		transition: all 0.1s ease-in-out;

		&[disabled] {
			cursor: default;
		}

		&:hover:not([disabled]),
		&.active {
			box-shadow: inset 0 0 0 3em #601e1d;
			border: 1px solid #601e1d;
			background: #601e1d;
		}
	}

	&.btn-light {
		background: #4163ae;
		box-shadow: inset 0 0 0 0 #4163ae;
		border: 1px solid #4163ae;
		color: #fff;
		cursor: pointer;
		transition: all 0.1s ease-in-out;

		&:hover,
		&.active {
			box-shadow: inset 0 0 0 3em #601e1d;
			border: 1px solid #601e1d;
			background: #601e1d;
		}
	}

	&.btn-active {
		background: #4163ae;
		cursor: default;
		color: #ffffff;
	}

	&.btn-inactive {
		background: rgba( 0,0,0,0.4 );
		color: #ad4242;
		cursor: default;
	}

	&.btn-previous {
		background: #232e46;

		&:hover {
			background: #4163ae;
		}
	}

	&.btn-dark {
		cursor: pointer;
	}
}
.menu .btn:last-of-type,
aside .btn:last-of-type {
	margin-right: 0;
}

.red {
	.btn {
		&.btn-secondary {
			&:hover,
			&.active {
				color: #ad4242;
			}
		}

		&.btn-primary {
			background: #4163ae;
			border-color: #4163ae;

			&:hover:not([disabled]),
			&.active {
				box-shadow: inset 0 0 0 3em #fff;
				border: 1px solid #fff;
				color: #ad4242;
			}
		}

		&.btn-dark {
			&:hover:not([disabled]),
			&.active {
				box-shadow: inset 0 0 0 3em #fff;
				border: 1px solid #fff;
				color: #ad4242;
			}
		}
	}

	aside {
		.col-md-3 {
			.btn {
				&.btn-primary {
					background: #ad4242;
					box-shadow: inset 0 0 0 0 #ad4242;
					border: 1px solid #ad4242;
					color: #fff;
					cursor: pointer;
					transition: all 0.1s ease-in-out;

					&:hover,
					&.active {
						border: 1px solid #ad4242;
						background: #fff;
						color: #ad4242;
					}
				}
			}
		}

		.btn {
			&.btn-primary,
			&.btn-dark {
				&:hover:not([disabled]),
				&.active {
					border: 1px solid #ad4242;
				}
			}
		}
	}
}

.dark-red {
	.btn {
		&.btn-secondary {
			&:hover,
			&.active {
				color: #ad4242;
			}
		}

		&.btn-primary {
			background: #4163ae;
			border-color: #4163ae;

			&:hover:not([disabled]),
			&.active {
				box-shadow: inset 0 0 0 3em #fff;
				border: 1px solid #fff;
				color: #ad4242;
			}
		}
	}
}

.gray {
	.btn {
		&.btn-secondary {
			&:hover,
			&.active {
				color: #666;
			}
		}

		&.btn-primary {
			&.blue {
				background: #4163ae;
				border-color: #4163ae;

				&:hover:not([disabled]),
				&.active {
					box-shadow: inset 0 0 0 3em #fff;
					border: 1px solid #fff;
					color: #ad4242;
				}
			}
		}
	}
}

.white {
	.btn {
		&.btn-secondary {
			box-shadow: inset 0 0 0 #ccc;
			border: 1px solid #ccc;
			color: #999;

			&:hover,
			&.active {
				color: #fff;
				box-shadow: inset 0 0 0 3em #999;
				border-color: #999;
			}
		}

		&.btn-active {
			background: #2d2d2d;
		}

		&.btn-inactive {
			background: #ccc;
		}

		&.btn-previous {
			background: #ccc;

			&:hover {
				background: #2d2d2d;
			}
		}
	}

	nav {
		.btn {
			&.btn-primary {
				&:hover,
				&.active {
					box-shadow: inset 0 0 0 3em #ad4242 !important;
					border-color: #ad4242 !important;
				}
			}
		}
	}
}
</style>