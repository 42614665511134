<template>
	<nav class="text-right register-buttons">
		<input-button
			v-if="showButton('add-attendee')"
			:to="{ name: 'register-attendee', params: { key: registrationKey }, query: { new: true} }"
			type="secondary"
		>Add Attendee</input-button>
		<input-button
			v-if="showButton('summary-return')"
			:to="{ name: 'register-summary', params: { key: registrationKey } }"
			type="secondary"
		>Return to Summary</input-button>
		<input-button
			v-if="showButton('cancel')"
			:to="{ name: 'register-cancel', params: { key: registrationKey } }"
			type="secondary"
		>Cancel Registration</input-button>
		<input-button
			v-if="showButton('next')"
			:submit="true"
			type="primary"
			:disabled="submitDisabled"
		>{{ nextButtonText }}</input-button>
	</nav>
</template>

<script>
export default {
	props : {
		nextButtonText : {
			type : String,
			default () {
				return 'Next Page'
			}
		},
		buttons : {
			type : Array,
			default () {
				return [
					'cancel',
					'next'
				]
			}
		},
		registrationKey : {
			type     : String,
			required : true
		},
		submitDisabled : {
			type     : Boolean,
			required : false,
			default () {
				return false
			}
		}
	},
	methods : {
		showButton (button) {
			return -1 !== this.buttons.indexOf(button)
		}
	},
	created () {

	}
}
</script>

<style lang="scss">
.text-right.register-buttons {
	.btn:not(:last-child) {
		margin-right: 1em;
	}
}
</style>