import Vue from 'vue'

// Plugins
import '@/plugins'
import '@/registration/plugins'

// Components
import '@/components'
import '@/registration/components'

// Sync router with store
import { sync } from 'vuex-router-sync'

import App from '@/registration/App.vue'
import router from '@/registration/router'
import store from '@/registration/store'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

// Sync store with router
sync(store, router)

Vue.config.productionTip = false

new Vue({
	router,
	store,
	render : h => h(App)
}).$mount('#app')