<template>
	<header>
		<core-loader v-if="loading" />

		<div class="container">
			<div :id="'logo-' + logo">TeenPact</div>
			<core-navigation
				v-if="showLinks"
				:links="links"
			/>
		</div>
		<div style="clear:both;"></div>
	</header>
</template>

<script>
export default {
	props : {
		logo : {
			type    : String,
			default : 'small'
		},
		showLinks : {
			type    : Boolean,
			default : true
		},
		links : Array
	},
	computed : {
		loading () {
			return this.$store.state.loading || this.$store.state.updating
		}
	}
}
</script>