<template>
	<form
		@submit.prevent="submitPayment"
	>
		<div
			v-if="totals.dueNowAmount > 0"
		>
			<br><br>
			<h4>Billing Address</h4>
			<div class="row">
				<div
					v-for="(field, index) in accountFields"
					:class="field.classSize"
					:key="index"
				>
					<label
						:for="field.name"
					>{{ field.label }} <span v-if="field.required" class="required">*</span></label>
					<input
						class="form-control"
						:placeholder="field.placeholder"
						:type="field.type"
						autocomplete="off"
						autocapitalize="off"
						v-model.lazy="billing[field.name]"
						:name="field.name"
					/>
				</div>
			</div>
			<div class="row account">
				<div
					v-for="(field, index) in addressFields"
					:class="field.classSize"
					:key="index"
				>
					<label
						:for="field.name"
					>{{ fieldLabel(field.label) }} <span v-if="field.required" class="required">*</span></label>
					<input
						v-if="field.type === 'text' || field.type === 'email'"
						autocomplete="off"
						autocapitalize="off"
						class="form-control"
						:placeholder="field.placeholder"
						:type="field.type"
						v-model.lazy="billing[field.name]"
						:name="field.name"
					/>
					<input-select
						v-model="billing[field.name]"
						v-if="field.type === 'select'"
						:selector="'register-billing-' + field.options"
						:options="addressFieldOptions(index)"
						:selected="billing[field.name]"
						:name="field.name"
					/>
				</div>
			</div>
		</div>

		<br><br>
		<aside>
			<div class="row">
				<div class="col-md-6">
					<span class="bold">Grand Total:</span> {{ totals.grandTotal | currency }}</div>
				<div class="col-md-6 col-md-text-right">
					<span class="bold">{{ registering ? 'Due Now' : 'Remaining Balance' }}:</span> {{ totals.dueNowAmount | currency }}</div>
			</div>
		</aside>

		<aside
			v-if="events.donationsEnabled && allDonations.length"
			class="dark donations-description mt-5"
		>
			<div
				v-if="events.donationsDescription"
				v-html="events.donationsDescription"
			/>

			<div class="row">
				<div class="col-md-8">
					<div
						class="row mt-2"
						v-for="(donation, index) in donations"
						:key="index"
					>
						<div class="col-md-6">
							<input-select
								:options="getDonationOptions"
								:selected="donation.id"
								@change="selectChange"
								:name="`donation-${index}`"
								placeholder="Scholarship Type"
							/>
						</div>

						<div class="col-md-6">
							<money
								allowBlank
								class="form-control text-right"
								v-model.lazy="donation.amount"
								placeholder="$0.00"
							/>
						</div>
					</div>

					<button
						class="btn btn-primary mt-2"
						@click.prevent="donations.push({ id: null, amount: 0 })"
					>
						Add Donation
					</button>
				</div>

				<div class="col-md-4 row mt-2 authorize-donation">
					<div class="col-12">
						<label
							class="d-flex"
							style="text-transform: none;font-size: 0.7em;"
						>
							<input
								style="margin-right: 8px;"
								type="checkbox"
								v-model="donationTerms"
								class="regular-checkbox"
							>
							&nbsp; I authorize the payment information given below to be used for this transaction.
						</label>
					</div>
				</div>
			</div>
		</aside>

		<div
			v-if="((totals.dueNowAmount > 0 || totals.grandTotal > totals.dueNowAmount) && account.amounts) || hasDonations"
		>
			<br><br>
			<h4>Payment</h4>

			<div class="row radio-group">
				<div class="col-12">
					<label
						v-for="(gateway, index) in gateways"
						:key="index"
					>
						<input
							type="radio"
							class="regular-radio"
							:value="gateway.id || gateway.value"
							v-model="selectedGateway"
						/>
						{{ gateway.label }}
					</label>
				</div>
			</div>

			<div
				class="payment-errors"
				ref="payment-errors"
			>
				<core-notice
					title="Payment Errors"
					:notices="paymentErrors"
				/>
			</div>

			<gateway
				:gateway="selectedGateway"
			/>

			<br><br>
			<h4>Registrations</h4>
			<div class="row account">
				<div
					class="col-md-12 text-right mb-2"
				>
					<span>Payment Amount</span>
				</div>
				<div
					v-for="(a, attendeeId) in attendees"
					:key="attendeeId"
					class="col-md-12"
				>
					<div
						v-for="(e, eventId) in a.events"
						:key="eventId"
						class="row"
					>
						<div class="col-md-9">
							<label>
								{{ e.name }} total for {{ a.name }} - {{ e.subtotal | currency }}
							</label>
						</div>
						<div class="col-md-3 text-right">
							<span v-if="!e.deposit">{{ e.subtotal | currency }}</span>
							<money
								v-if="e.deposit"
								allowBlank
								:min="e.minimum"
								:max="parseFloat(e.due) || e.subtotal"
								class="form-control text-right"
								v-model.lazy="amounts[eventId]"
								:placeholder="getMinimumOrDue(e)"
							/>
						</div>
					</div>
				</div>
				<div class="col-md-12 text-right payment-total">
					Registrations Total:&nbsp;&nbsp;&nbsp;{{ getAmounts() | currency }}
					<div
						v-if="hasDonations"
						class="mt-3"
					>
						Donation Total: &nbsp;&nbsp;&nbsp;{{ donationAmounts | currency }}
					</div>

					<aside class="mt-3">
						<div class="row">
							<div class="col-12">
								<span class="bold">Total Payment:</span> &nbsp;&nbsp;&nbsp;{{ getPaymentAmount | currency }}
							</div>
						</div>
					</aside>
				</div>
			</div>
		</div>

		<br><br>
		<h4>Terms &amp; Conditions<span class="required">*</span></h4>
		<div v-html="gateway.termsAndConditions" />
		<div class="radio-group">
			<label>
				<input
					type="checkbox"
					v-model="setTerms"
					class="regular-checkbox"
				>
				&nbsp; <em>I Agree to the Terms &amp; Conditions</em></label>
		</div>
		<br>
		<div class="pull-right">
			<input-button
				type="primary"
				@click="submitRegistration"
				v-if="$store.state.isAdmin"
			>
				Submit Registration - No Payment
			</input-button>

			<input-button
				type="primary"
				:submit="true"
				:disabled="submitDisabled"
			>
				Submit
			</input-button>
		</div>
	</form>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import { Scroll } from '@/registration/mixins'
export default {
	mixins : [ Scroll ],
	props  : {
		account : {
			type     : Object,
			required : true
		},
		attendees : {
			type     : Array,
			required : true
		},
		submit : {
			type     : Function,
			required : true
		},
		totals : {
			type     : Object,
			required : true
		},
		gateways : {
			type     : Array,
			required : true
		},
		registering : Boolean
	},
	data () {
		return {
			paymentErrors : [],
			terms         : false,
			amounts       : {},
			donationTerms : false,
			donations     : [
				{
					id     : null,
					amount : 0
				}
			],
			billing : {
				first_name : null,
				last_name  : null,
				address1   : null,
				address2   : null,
				city       : null,
				state      : null,
				zip        : null,
				country    : null
			}
		}
	},
	watch : {
		account   : 'setBillingFromAccount',
		attendees : 'updateAmounts'
	},
	computed : {
		...mapGetters('donations', {
			allDonations : 'get'
		}),
		...mapState('payment', [ 'paymentData' ]),
		...mapState('settings', [ 'gateway', 'events' ]),
		...mapGetters('attendees', {
			getAttendeeName  : 'name',
			getAttendeeIndex : 'getIndex'
		}),
		getPaymentAmount () {
			return parseInt(this.donationAmounts) + parseInt(this.getAmounts())
		},
		hasDonations () {
			let valid = false
			this.donations.forEach(d => (valid = valid || (d.id && d.amount)))
			return valid && 0 < this.donationAmounts && this.donationTerms
		},
		donationAmounts () {
			let amounts = 0
			this.donations.map(d => (amounts += d.amount))
			return amounts
		},
		addDonationDisabled () {
			return true
		},
		getDonationOptions () {
			return this.allDonations.map(d => {
				return {
					value : d.id,
					label : d.name
				}
			})
		},
		selectedGateway : {
			get () {
				return this.paymentData.gateway
			},
			set (newValue) {
				this.setGateway(newValue)
			}
		},
		setTerms : {
			get () {
				return this.terms
			},
			set () {
				this.terms = !this.terms

				if (this.terms) {
					const shouldChange = this.checkAmounts()

					Object.keys(this.attendees).forEach(attendeeId => {
						const a = this.attendees[attendeeId]
						Object.keys(a.events).forEach(eventId => {
							const e      = a.events[eventId]
							const amount = this.amounts[eventId]
							if (e.due) {
								if (!amount && shouldChange) {
									this.amounts[eventId] = e.due
								}
							} else {
								if (amount < e.minimum && shouldChange) {
									this.amounts[eventId] = e.minimum
								}
							}
						})
					})
				}
			}
		},
		hasMinimumAmounts () {
			const shouldCheck = this.checkAmounts()
			let hasMinimum    = true

			this.updateAmounts()

			if (0 >= this.totals.dueNowAmount) {
				return true
			}

			Object.keys(this.attendees).forEach(attendeeId => {
				const a = this.attendees[attendeeId]
				Object.keys(a.events).forEach(eventId => {
					const e = a.events[eventId]
					if (e.deposit && shouldCheck) {
						if (!this.amounts[eventId]) {
							hasMinimum = false
						} else if (parseFloat(this.amounts[eventId]) < e.minimum) {
							hasMinimum = false
						}
					}
				})
			})
			return hasMinimum
		},
		submitDisabled () {
			return !this.terms || !this.hasMinimumAmounts
		},
		accountFields () {
			return this.$constants.ACCOUNT_FORM_FIELDS.filter(field => {
				return 'first_name' === field.name || 'last_name' === field.name
			})
		},
		countries () {
			return this.$constants.COUNTRIES
		},
		states () {
			return 'US' === this.billing.country ? this.$constants.US_STATES : this.$constants.CA_STATES
		},
		stateOrProvince () {
			return 'US' === this.billing.country ? 'State' : 'Province'
		},
		addressFields () {
			return this.$constants.ACCOUNT_ADDRESS_FIELDS
		}
	},
	methods : {
		...mapMutations('payment', [ 'setGateway', 'setAmounts', 'setDonations' ]),
		selectChange (payload) {
			Object.keys(payload).forEach(key => {
				const index = key.replace('donation-', '')
				this.$set(this.donations[index], 'id', payload[key])
				this.donationTerms = true
			})
		},
		attendeeName (attendeeId) {
			const index = this.getAttendeeIndex(attendeeId)
			return this.getAttendeeName(index)
		},
		addressFieldOptions (id) {
			return 'string' === typeof this.addressFields[id].options ? this[this.addressFields[id].options] : this.addressFields[id].options
		},
		fieldLabel (label) {
			return label || this.stateOrProvince
		},
		setBillingFromAccount () {
			this.billing.first_name = this.account.first_name
			this.billing.last_name  = this.account.last_name
			this.billing.address1   = this.account.address1
			this.billing.address2   = this.account.address2
			this.billing.city       = this.account.city
			this.billing.state      = this.account.state
			this.billing.zip        = this.account.zip
			this.billing.country    = this.account.country
		},
		submitRegistration () {
			this.$store.commit('loading', true)
			this.setGateway('admin')
			return this.submit()
				.catch(error => {
					this.paymentErrors.push(error)
					this.$store.commit('loading', false)
				})
		},
		submitPayment () {
			if (this.submitDisabled) {
				return
			}

			this.setAmounts(this.amounts)

			if (this.donationTerms) {
				this.setDonations(this.donations)
			}

			this.paymentErrors = []
			this.$store.commit('loading', true)

			if (0 >= this.totals.dueNowAmount && !this.hasDonations) {
				this.setGateway('free')
				return this.submit()
					.catch(error => {
						this.paymentErrors.push(error)
						this.$store.commit('loading', false)
					})
			}

			// Check for account credit.
			if (this.account.amounts.account_credit && this.account.amounts.account_credit > this.totals.dueNowAmount && !this.hasDonations) {
				return Promise.resolve()
					.then(() => this.submit(this.billing))
					.catch(error => {
						this.paymentErrors.push(error)
						this.scroll(this.$refs['payment-errors'])
						this.$store.commit('loading', false)
					})
			}

			const promise = new Promise((resolve, reject) => {
				this.$bus.$emit(`submit-payment-${this.selectedGateway}`, this.billing, resolve, reject)
			})

			promise.then(() => this.submit(this.billing))
				.catch(error => {
					this.paymentErrors.push(error)
					this.scroll(this.$refs['payment-errors'])
					this.$store.commit('loading', false)
				})
		},
		updateAmounts () {
			Object.keys(this.attendees).forEach(attendeeId => {
				const a = this.attendees[attendeeId]
				Object.keys(a.events).forEach(eventId => {
					const e = a.events[eventId]
					if (!e.deposit) {
						this.amounts[eventId] = e.subtotal
					}
				})
			})
		},
		getAmounts () {
			return this.$amounts.arraySum(Object.keys(this.amounts).map(k => this.amounts[k]))
		},
		getMinimumOrDue (e) {
			if (e.due) {
				return 'Due ' + this.$options.filters.currency(e.due)
			}

			return 'Minimum ' + this.$options.filters.currency(e.minimum)
		},
		checkAmounts () {
			// Do an initial loop through the attendees/amounts
			// and check if we should forcibly change the values.
			const maybeChangeDue    = []
			const maybeChangeAmount = []
			Object.keys(this.attendees).forEach(attendeeId => {
				const a = this.attendees[attendeeId]
				Object.keys(a.events).forEach(eventId => {
					const e      = a.events[eventId]
					const amount = this.amounts[eventId]
					if (e.due) {
						maybeChangeDue.push(!!e.due)
						maybeChangeAmount.push(!!amount || amount)
					} else {
						maybeChangeDue.push(false)
						maybeChangeAmount.push(!!amount || e.minimum)
					}
				})
			})

			return maybeChangeDue.every(b => b) && maybeChangeAmount.some(b => b) ? false : maybeChangeDue.every(b => b) || maybeChangeAmount.some(b => b)
		}
	},
	mounted () {
		this.setBillingFromAccount()
		this.updateAmounts()
	}
}
</script>

<style lang="scss">
.donations-description {
	p:last-child {
		margin-bottom: 0;
	}
}

.payment-total {
	font-weight: 600;
	font-size: 18px;
	margin-top: 10px;
}
.credit-card-logo {
	opacity: 1;

	&.not-selected {
		opacity: 0.3;
	}
}

/**
 * The CSS shown here will not be introduced in the Quickstart guide, but shows
 * how you can use CSS to style your Element's container.
 */
.StripeElement {
	font-size: 1.1em;
	font-family: crimson;
	margin-bottom: 0.6em;
	padding: 0.8em 0.8em 0.4em;
	border-radius: 0;
	border: none;
	display: block;
	width: 100%;
	line-height: 1.25;
	height: 42px;
	color: #495057;
	background-color: #fff;
	background-image: none;
	background-clip: padding-box;
	transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.StripeElement--invalid {
	border-color: #fa755a;
}

.StripeElement--webkit-autofill {
	background-color: #fefde5 !important;
}

@media (max-width: 575.98px) {
	.authorize-donation input {
		min-height:20px;
		min-width:20px;
	}
}
</style>